var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","show-select":"","headers":[
    {
      text: '',
      value: 'imagen',
    },
    {
      text: 'Nombre',
      value: 'nombre',
    },
    { text: 'Email', value: 'email' } ],"items":_vm.all_users_datasource,"search":_vm.search_user,"item-key":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"ml-5 mr-5",attrs:{"label":"Buscar"},model:{value:(_vm.search_user),callback:function ($$v) {_vm.search_user=$$v},expression:"search_user"}})]},proxy:true},{key:"item.imagen",fn:function(ref){
  var item = ref.item;
return [_c('UserAvatar',{attrs:{"id_user":item.id}})]}}]),model:{value:(_vm.usuarios),callback:function ($$v) {_vm.usuarios=$$v},expression:"usuarios"}})}
var staticRenderFns = []

export { render, staticRenderFns }