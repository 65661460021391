


































import { Component, PropSync, Vue } from "vue-property-decorator";
import { Usuario } from "@/shared/dtos/usuario";
@Component({
  components: {
    UserAvatar: () => import("@/components/Usuarios/UserAvatar.vue"),
  },
  $_veeValidate: { validator: "new" },
})
export default class UsuariosListSelect extends Vue {
  @PropSync("usuarios_seleccionados", { default: [] }) usuarios!: Usuario[];
  @PropSync("todos", { default: [] }) all_users_datasource!: Usuario[];
  public search_user: string = "";
}
